.email-simulation {
    font-family: 'Google Sans', 'Roboto', 'Arial', sans-serif;
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

.email-header {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f7f7f7;
}

.email-subject {
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
}

.email-from {
    font-size: 14px;
    color: #666;
}

.email-display-name {
    font-weight: bold;
}

.email-address {
    margin-left: 4px;
}

.email-body {
    padding: 16px;
}

.simulation-loading, .simulation-error {
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    margin: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

/* Additions to the previous CSS */

.email-toolbar {
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    display: flex;
    justify-content: start;
    gap: 8px;
}

.btn {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.btn:hover {
    background-color: #0056b3;
}

.email-date {
    margin-left: auto;
    color: #666;
    font-size: 12px;
}

.email-footer {
    padding: 16px;
    border-top: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    text-align: center;
}

.email-footer a {
    color: #007bff;
    text-decoration: none;
    margin: 0 5px;
}

.email-footer a:hover {
    text-decoration: underline;
}

/* Browser Window Styles */
.browser-window {
    width: 80%;
    height: 90vh;
    margin: 20px auto;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.browser-header {
    padding: 8px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
}

.window-controls {
    display: flex;
    gap: 8px;
}

.close-button,
.minimize-button,
.fullscreen-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ccc;
}

.close-button { background: #ff605c; }
.minimize-button { background: #ffbd44; }
.fullscreen-button { background: #00ca4e; }

.url-bar {
    margin-left: 16px;
    border: none;
    padding: 8px;
    width: 60%;
    border-radius: 4px;
}

.browser-content {
    flex-grow: 1;
    overflow-y: auto;
}

/* Continue with your existing email simulation styles */
